import { Builder } from 'builder-pattern';
import { create } from 'zustand';
import { loadingStore } from '@modules/core/adapter/outbound/stores/ui.store';
import { GetGameBannerUsecase } from '@modules/home/application/usecases/gameBanner.usecase';
import { IGameBanner } from '@modules/home/domain/models/gameBanner.model';
import { GameBannerRepositoryImpl } from '../../inbound/repositories/gameBanner.repository';
export interface GameBannerState {
  gameBanner: IGameBanner;
  setGameBanner: (page: string) => void;
}
export const gameBannerStore = create<GameBannerState>(set => ({
  gameBanner: Builder<IGameBanner>().build(),
  setGameBanner: async (page: string): Promise<void> => {
    try {
      loadingStore.setState({
        isLoading: true
      });
      const gameBannerRepoImpl = new GameBannerRepositoryImpl();
      const getGameBannerUsecase = new GetGameBannerUsecase(gameBannerRepoImpl);
      const result = await getGameBannerUsecase.handle(page);
      if (result.isRight()) {
        set(() => ({
          gameBanner: result.value
        }));
      }
    } catch (error) {
      console.error('Error setGameBanner store', error);
    } finally {
      loadingStore.setState({
        isLoading: false
      });
    }
  }
}));