import { create } from 'zustand';
import { GetRecommendProductUsecase } from '@modules/home/application/usecases/getRecommendProduct.usecase';
import { IProduct } from '@shared/models/product.model';
import { RecommendProductRepositoryImpl } from '../../inbound/repositories/recommendProduct.repository';
export interface RecommendProductState {
  recommendProduct: IProduct[];
  setRecommendProduct: () => void;
}
export const recommendProductStore = create<RecommendProductState>(set => ({
  recommendProduct: [],
  setRecommendProduct: async () => {
    try {
      const recommendProductRepositoryImpl = new RecommendProductRepositoryImpl();
      const getRecommendProductUsecase = new GetRecommendProductUsecase(recommendProductRepositoryImpl);
      const result = await getRecommendProductUsecase.handle();
      if (result.isRight()) {
        set(() => ({
          recommendProduct: result.value
        }));
      }
    } catch (error) {
      console.error('error setRecommendProduct', error);
    }
  }
}));