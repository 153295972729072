import { Builder } from 'builder-pattern';
import { create } from 'zustand';
import { loadingStore } from '@modules/core/adapter/outbound/stores/ui.store';
import { GetHomeCampaignUsecase } from '@modules/home/application/usecases/campaign.usecase';
import { IHomeCampaign } from '@modules/home/domain/models/campaign.model';
import { HomeCampaignRepositoryImpl } from '../../inbound/repositories/campaign.repository';
export interface HomeCampaignState {
  homeCampaign: IHomeCampaign;
  setHomeCampaign: () => void;
}
export const homeCampaignStore = create<HomeCampaignState>(set => ({
  homeCampaign: Builder<IHomeCampaign>().build(),
  setHomeCampaign: async (): Promise<void> => {
    try {
      loadingStore.setState({
        isLoading: true
      });
      const homeCampaignRepoImpl = new HomeCampaignRepositoryImpl();
      const getHomeCampaignUsecase = new GetHomeCampaignUsecase(homeCampaignRepoImpl);
      const result = await getHomeCampaignUsecase.handle();
      if (result.isRight()) {
        set(() => ({
          homeCampaign: result.value
        }));
      }
    } catch (err) {
      console.error('Error setHomeCampaign ', err);
    } finally {
      loadingStore.setState({
        isLoading: false
      });
    }
  }
}));