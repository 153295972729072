import { create } from 'zustand';
import { GetRecommendTipsTricksUsecase } from '@modules/tipsAndTricks/application/usecases/getRecommendTipsTricks.usecase';
import { ITipCard } from '@shared/models/tipsAndTricks.model';
import { RecommendTipsTricksRepositoryImpl } from '../../inbound/repositories/recommendTipsTricks.repository';
export interface RecommendTipsTricksState {
  recommendTipsTricks: ITipCard[];
  setRecommendTipsTricks: () => void;
}
export const recommendTipsTricksStore = create<RecommendTipsTricksState>(set => ({
  recommendTipsTricks: [],
  setRecommendTipsTricks: async () => {
    try {
      const recommendTipsTricksRepositoryImpl = new RecommendTipsTricksRepositoryImpl();
      const getRecommendTipsTricksUsecase = new GetRecommendTipsTricksUsecase(recommendTipsTricksRepositoryImpl);
      const result = await getRecommendTipsTricksUsecase.handle();
      if (result.isRight()) {
        set(() => ({
          recommendTipsTricks: result.value
        }));
      }
    } catch (error) {
      console.error('error setRecommendTipsTricks ', error);
    }
  }
}));