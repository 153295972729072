import { match } from 'ts-pattern';
import { create } from 'zustand';
import { loadingStore } from '@modules/core/adapter/outbound/stores/ui.store';
import { GetCpConnectListUsecase } from '@modules/cpConnect/application/usecases/getCpConnectList.usecase';
import { ICpConnect } from '@modules/cpConnect/domain/models/cpConnect.model';
import { CpConnectRepositoryImpl } from '../../inbound/repositories/cpConnect.repository';
export interface CpConnectListState {
  homeCpConnectList: ICpConnect[];
  cpConnectList: ICpConnect[];
  fetchCpConnectList: (type: string, limit?: number) => void;
}
const initialState: ICpConnect[] = [];
export const cpConnectListStore = create<CpConnectListState>(set => ({
  homeCpConnectList: initialState,
  cpConnectList: initialState,
  fetchCpConnectList: async (type: string, limit?: number) => {
    try {
      loadingStore.setState({
        isLoading: true
      });
      const cpConnectRepositoryImpl = new CpConnectRepositoryImpl();
      const getCpConnectListUsecase = new GetCpConnectListUsecase(cpConnectRepositoryImpl);
      const result = await getCpConnectListUsecase.handle(limit);
      if (result.isRight()) {
        match(type).with('home', () => set(() => ({
          homeCpConnectList: result.value
        }))).with('cp-connect', () => set(() => ({
          cpConnectList: result.value
        }))).otherwise(() => set(() => ({
          cpConnectList: result.value
        })));
      }
    } catch (error) {
      console.error('error setCpConnectList ', error);
    } finally {
      loadingStore.setState({
        isLoading: false
      });
    }
  }
}));